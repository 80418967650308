import React from 'react';
import {
  CancelPresentation,
  Logout,
  Mic,
  MicOff,
  ScreenShare,
  StopScreenShare,
  Videocam,
  VideocamOff,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import eyeson, { StreamHelpers } from 'eyeson/index';

import { PlayerContainer, SettingsBar, Video } from './styled';
import Settings from './Settings';
import Layouts from './Layouts';
import Layers from './Layers';
import InviteGuest from './InviteGuest';
import Chat from './Chat';
import IconButton from '../IconButton';
import useEyesonEvents from '../../utils/hooks/useEyesonEvents';
import eventTypes from '../../constants/eventTypes';
import Recordings from './Recordings';
import api from '../../services';

const VideoPlayer = ({
  remoteStream,
  audio,
  video,
  screen,
  setStreamState,
  isCreator,
  accessKey,
  endSession,
  name,
}) => {
  //recording here for future recording indicator on stream
  const [recording, setRecording] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [chatMessages, setChatMessages] = React.useState([]);
  const [layout, setLayout] = React.useState({
    name: 'auto',
    users: [],
    show_names: true,
    layout: 'auto',
  });
  //true because we cant get information about layers and after f5 for example
  //we needed to delete current layers on requests
  const [layer, setLayer] = React.useState({
    topLayer: true,
    bottomLayer: true,
    playback: true,
  });
  const commonProps = { accessKey, layer, layout, setLayer, setLayout };

  const sendChatMessage = (message) => {
    eyeson.send({
      type: eventTypes.send_chat,
      content: message,
    });
  };

  const { t } = useTranslation();
  const stream = React.useRef(null);

  const toggleAudio = React.useCallback(() => {
    setStreamState((prevState) => {
      const { audio, localStream } = prevState;
      StreamHelpers.toggleAudio(localStream, !audio);
      return { ...prevState, audio: !audio };
    });
  }, []);

  const toggleVideo = React.useCallback(() => {
    setStreamState((prevState) => {
      const { audio, video, localStream } = prevState;
      eyeson.send({
        type: eventTypes.change_stream,
        stream: localStream,
        video: !video,
        audio: audio,
      });
      return { ...prevState, video: !video };
    });
  }, []);

  const toggleScreen = React.useCallback(() => {
    if (!screen) {
      eyeson.send({
        type: eventTypes.start_screen_capture,
        audio: audio,
        screenStream: null,
        screen: true,
      });
      setStreamState((prevState) => {
        return { ...prevState, screen: true };
      });
    } else {
      eyeson.send({ type: eventTypes.stop_presenting });
    }
  }, [screen, audio]);

  const endSessionForAllParticipants = React.useCallback(() => {
    eyeson.send({
      type: eventTypes.terminate_meeting,
    });
    setStreamState((prevState) => {
      return {
        ...prevState,
        localStream: null,
        remoteStream: null,
        connecting: false,
      };
    });
  }, []);

  //add new user to users array
  //handler for future features
  const handleAddUserEvent = React.useCallback((event) => {
    const user = event.user;

    setUsers((prevState) => {
      return [...prevState, user];
    });
  }, []);

  //delete user from users array
  const handleRemoveUserEvent = React.useCallback((event) => {
    setUsers((prevState) => {
      return prevState.filter((user) => user.id !== event.id);
    });
  }, []);

  const chatMessageHandlerEvent = React.useCallback((event) => {
    setChatMessages((prevState) => {
      return [...prevState, event];
    });
  }, []);
  // used for get data about setted layouts
  const getRoom = async () => {
    try {
      const { data } = await api.eyeson.getRoom(accessKey);
      const { show_names, layout_name, layout_users } = data.options;
      setLayout((prevState) => {
        return {
          ...prevState,
          name: layout_name ? layout_name : 'auto',
          users: layout_users,
          show_names: show_names,
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    //get initial room data needed for layouts
    //todo mb change to event but need to think about layout state
    getRoom();
  }, []);

  React.useEffect(() => {
    if (remoteStream && stream.current) {
      stream.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  useEyesonEvents({
    [eventTypes.add_user]: handleAddUserEvent,
    [eventTypes.remove_user]: handleRemoveUserEvent,
    [eventTypes.chat]: chatMessageHandlerEvent,
  });

  return (
    <>
      {isCreator && (
        <SettingsBar>
          {/*TODO icon for recording and icon to show recording status for users*/}
          <Layers {...commonProps} />
          <Layouts {...commonProps} users={users} />
          <Recordings recording={recording} setRecording={setRecording} />
          <IconButton
            onClick={endSessionForAllParticipants}
            title={t('common.iconButton.endForAllParticipants')}
          >
            <CancelPresentation fontSize={'large'} />
          </IconButton>
        </SettingsBar>
      )}

      <PlayerContainer>
        <Video playsInline autoPlay ref={stream} />
      </PlayerContainer>

      <SettingsBar>
        <IconButton onClick={toggleAudio} title={t('common.iconButton.toggleAudio')}>
          {audio ? <Mic fontSize={'large'} /> : <MicOff fontSize={'large'} />}
        </IconButton>
        <IconButton
          onClick={toggleVideo}
          disabled={screen}
          title={t('common.iconButton.toggleVideo')}
        >
          {video ? <Videocam fontSize={'large'} /> : <VideocamOff fontSize={'large'} />}
        </IconButton>
        <IconButton onClick={toggleScreen} title={t('common.iconButton.toggleScreen')}>
          {!screen ? <ScreenShare fontSize={'large'} /> : <StopScreenShare fontSize={'large'} />}
        </IconButton>
        <Settings streamState={{ audio, video, screen }} />
        <Chat chatMessages={chatMessages} sendChatMessage={sendChatMessage} name={name} />
        <InviteGuest />
        <IconButton onClick={endSession} title={t('common.iconButton.leaveSession')}>
          <Logout fontSize={'large'} />
        </IconButton>
      </SettingsBar>
    </>
  );
};

export default VideoPlayer;
