import * as Yup from 'yup';

const signUpSchema = () =>
  Yup.object().shape({
    email: Yup.string().max(40).email().default('').required(),
    firstName: Yup.string().min(3).max(40).default('').required(),
    lastName: Yup.string().min(3).max(40).default('').required(),
    password: Yup.string().max(40).min(5).default('').required(),
    confirmPassword: Yup.string()
      .min(5)
      .max(40)
      .default('')
      .required()
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),

    eyesonApiKey: Yup.string().min(42).max(42).default('').required(),
  });

export default signUpSchema;
