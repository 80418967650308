import React from 'react';
import { CircularProgress } from '@mui/material';

import { Layout } from './styled';

const Loader = ({ fullPage }) => {
  return (
    <Layout fullPage={fullPage}>
      <CircularProgress />
    </Layout>
  );
};

export default Loader;
