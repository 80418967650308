import React from 'react';
import { TextField } from 'formik-mui';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordField = ({ ...props }) => {
  const [isPasswordHidden, setIsPasswordHidden] = React.useState(true);

  const toggleVisibilityPassword = () => {
    setIsPasswordHidden((prevState) => {
      return !prevState;
    });
  };

  return (
    <TextField
      type={isPasswordHidden ? 'password' : 'text'}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={toggleVisibilityPassword}>
              {isPasswordHidden ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
