import React from 'react';

import FormLayout from '../../components/Forms';
import LoginForm from '../../components/Forms/LoginForm';

const Login = () => {
  return <FormLayout component={LoginForm} />;
};

export default Login;
