import React from 'react';
import { Card } from '@mui/material';
import { Container, CustomCardContent } from './styled';

const FormLayout = ({ component: Component }) => {
  // onChange for formik
  const handleChange = (fieldName, setFieldValue) => (event) => {
    const newValue = event.target.value;

    setFieldValue(fieldName, newValue);
  };

  return (
    <Container>
      <Card>
        <CustomCardContent>
          <Component handleChange={handleChange} />
        </CustomCardContent>
      </Card>
    </Container>
  );
};

export default FormLayout;
