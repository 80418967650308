import styled from 'styled-components';
import { AppBar, Avatar, Button } from '@mui/material';

import MuiNavLink from '../../router/components/MuiNavLink';

export const CustomAppBar = styled(AppBar)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  padding: 0 24px;
`;

export const Navigation = styled.nav`
  display: flex;

  margin: 0 auto 0 48px;
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  gap: 24px;

  list-style: none;
`;

export const MenuItem = styled.li`
  display: block;
`;

export const MenuLink = styled(MuiNavLink)`
  font-size: 20px;
  text-decoration: none;
  color: #ffffff;
`;

export const AuthMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const UserAvatar = styled(Avatar)`
  background-color: #ffffff;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const Logout = styled(Button)`
  font-size: 20px;
  text-transform: none;
  color: white;
`;
