import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { Container, CustomLink, CustomTable } from './styled';
import Loader from '../../components/Loader';

import api from '../../services';
import StatusSelect from '../../components/StatusSelect';
import ROOM_STATUS from '../../constants/room-status';

const STATUS_FILTER_OPTIONS = Object.values(ROOM_STATUS).map((status) => ({
  name: status,
  value: status,
}));
const RoomManagement = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [rooms, setRooms] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState(ROOM_STATUS.BOOKED);

  const handleFilterChange = React.useCallback((event) => {
    const newStatusFilter = event.target.value;

    setStatusFilter(newStatusFilter);
  }, []);

  const getRooms = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const params = {
        status: statusFilter,
      };
      const { data } = await api.rooms.getRooms(params);
      setRooms(data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [statusFilter]);

  const createRoom = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.rooms.createRoom();
      if (data) {
        getRooms();
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  React.useEffect(() => {
    getRooms();
  }, [statusFilter]);

  if (isLoading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <StatusSelect
        labelId="status-filter"
        selectedValue={statusFilter}
        onChangeValue={handleFilterChange}
        labelText={t`roomManagement.filters.status`}
        options={STATUS_FILTER_OPTIONS}
      />
      <CustomTable component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('roomManagement.roomId')}</TableCell>
              <TableCell>{t('roomManagement.status')}</TableCell>
              <TableCell>{t('roomManagement.createdAt')}</TableCell>
              {statusFilter == ROOM_STATUS.FINISHED && (
                <TableCell>{t('roomManagement.recordings')}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.length !== 0 ? (
              rooms.map((room) => (
                <TableRow key={room.id}>
                  <TableCell>
                    <CustomLink to={`/rooms/${room.id}`}>{room.id}</CustomLink>
                  </TableCell>
                  <TableCell>{room.status}</TableCell>
                  <TableCell>
                    {DateTime.fromJSDate(new Date(room.createdAt)).toFormat(
                      'MMM dd, yyyy, HH:mm:ss',
                    )}
                  </TableCell>
                  {statusFilter == ROOM_STATUS.FINISHED && (
                    <TableCell>
                      {room?.recordings?.map((recording, index) => (
                        <div key={recording.id}>
                          <a
                            download
                            target="_blank"
                            rel="noreferrer"
                            href={recording.downloadLink}
                          >
                            {t('roomManagement.recordingNumber', { index: index + 1 })}
                          </a>
                        </div>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={statusFilter === ROOM_STATUS.FINISHED ? 4 : 3}>
                  {t('roomManagement.noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTable>
      <Button variant={'contained'} onClick={createRoom}>
        {t`roomManagement.createRoomButton`}
      </Button>
    </Container>
  );
};

export default RoomManagement;
