import React from 'react';
import { Button, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../context/auth';
import PasswordField from '../../PasswordField';
import signUpSchema from './SignUp.yup';
import { CustomForm } from '../styled';
import api from '../../../services';
import { setTokens } from '../../../utils/tokens/tokens';

const SignUpForm = ({ handleChange }) => {
  const schema = signUpSchema();
  const initialValues = signUpSchema().cast({});
  const { t } = useTranslation();
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const [registerError, setRegisterError] = React.useState(null);

  const signUp = React.useCallback(async (signUpData) => {
    setRegisterError(null);
    try {
      const { data, error } = await api.auth.signUp(signUpData);
      if (error) {
        //TODO error handling
        console.log(error);
        return;
      }

      if (data) {
        const accessToken = data.data.accessToken;
        const refreshToken = data.data.refreshToken;
        setUser(data.data.user);
        setTokens(accessToken, refreshToken);
        navigate('/', { replace: true });
      }
    } catch (err) {
      setRegisterError(err.response.data.error);
    }
  }, []);

  return (
    <>
      <Typography>{t('signUp.title')}</Typography>
      {registerError && <Typography color={'red'}>{registerError}</Typography>}
      <Formik validationSchema={schema} initialValues={initialValues} onSubmit={signUp}>
        {({ isValid, dirty, setFieldValue, isSubmitting }) => (
          <CustomForm>
            <Field
              required
              name={'email'}
              size={'small'}
              component={TextField}
              label={t('common.input.label.email')}
              onChange={handleChange('email', setFieldValue)}
            />
            <Field
              required
              name={'firstName'}
              size={'small'}
              component={TextField}
              label={t('common.input.label.firstName')}
              onChange={handleChange('firstName', setFieldValue)}
            />
            <Field
              required
              name={'lastName'}
              size={'small'}
              component={TextField}
              label={t('common.input.label.lastName')}
              onChange={handleChange('lastName', setFieldValue)}
            />
            <Field
              required
              name={'password'}
              size={'small'}
              component={PasswordField}
              label={t('common.input.label.password')}
              onChange={handleChange('password', setFieldValue)}
            />
            <Field
              required
              name={'confirmPassword'}
              size={'small'}
              component={PasswordField}
              label={t('common.input.label.confirmPassword')}
              onChange={handleChange('confirmPassword', setFieldValue)}
            />
            <Field
              required
              name={'eyesonApiKey'}
              size={'small'}
              component={TextField}
              label={t('common.input.label.eyesonApiKey')}
              onChange={handleChange('eyesonApiKey', setFieldValue)}
            />
            <Button
              disabled={!(isValid && dirty) || isSubmitting}
              variant={'contained'}
              type={'submit'}
            >
              {t('common.button.signUp')}
            </Button>
          </CustomForm>
        )}
      </Formik>
    </>
  );
};

export default SignUpForm;
