import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

import { CustomInputLabel } from './styled';

const SettingsSelect = ({
  onChangeValue,
  selectedValue,
  labelText,
  options,
  disabled,
  labelId,
}) => {
  return (
    <FormControl fullWidth>
      <CustomInputLabel id={labelId}>{labelText}</CustomInputLabel>
      <Select
        labelId={labelId}
        label={labelText}
        onChange={onChangeValue}
        value={selectedValue}
        size={'small'}
        disabled={disabled}
      >
        {options.map((el, i) => {
          return (
            <MenuItem value={el.value} key={i}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SettingsSelect;
