import React from 'react';

import { LogoImg } from './styled';
import LogoImage from '../../assets/images/logo-white.svg';

const Logo = () => {
  return <LogoImg src={LogoImage} />;
};

export default Logo;
