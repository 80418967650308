import React from 'react';
import eyeson from 'eyeson/index';
import { useTranslation } from 'react-i18next';

import IconButton from '../../IconButton';
import eventTypes from '../../../constants/eventTypes';
import useEyesonEvents from '../../../utils/hooks/useEyesonEvents';

const Recordings = ({ recording, setRecording }) => {
  const { t } = useTranslation();

  const handleRecordingUpdate = (event) => {
    if (!event.recording.duration && !event.recording.links.download) {
      setRecording(true);
    } else {
      setRecording(false);
    }
  };

  const handleRoomUpdate = (event) => {
    //set recording after recording was started
    setRecording(event.content.recording);
  };

  const toggleRecording = () => {
    if (recording) {
      eyeson.send({ type: eventTypes.stop_recording });
      setRecording(false);
    } else {
      eyeson.send({ type: eventTypes.start_recording });
      setRecording(true);
    }
  };

  useEyesonEvents(
    { [eventTypes.recording_update]: handleRecordingUpdate },
    { [eventTypes.room_setup]: handleRoomUpdate },
  );

  return (
    <IconButton onClick={toggleRecording} title={t('common.iconButton.toggleRecording')}>
      {recording ? 'stop rec' : 'start rec'}
    </IconButton>
  );
};

export default Recordings;
