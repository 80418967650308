import React from 'react';
import { DeviceManager } from 'eyeson/index';

import replaceInputs from '../utils/helpers/replaceInputsForSelect';

const StreamSettingsContext = React.createContext({});

// context for setting component and entering room component
function StreamSettingsProvider(props) {
  const [deviceManager, setDeviceManager] = React.useState(null);
  const [camerasState, setCamerasState] = React.useState([]);
  const [microphonesState, setMicrophonesState] = React.useState([]);
  const [selectedMicrophone, setSelectedMicrophone] = React.useState('');
  const [selectedCamera, setSelectedCamera] = React.useState('');

  // device manager add devices and options to local storage with storeConstraints method
  // and after eyeson.start event eyeson use local storage to get options and devices
  // to start stream with selected device and options
  const changeCamera = (e) => {
    const cameraId = e.target.value;
    setSelectedCamera(cameraId);
    deviceManager.setVideoInput(cameraId);
  };
  const changeMicrophone = (e) => {
    const microphoneId = e.target.value;
    deviceManager.setAudioInput(microphoneId);
    setSelectedMicrophone(microphoneId);
  };

  const setDefaultCamera = (cameras) => {
    const currentCamera = cameras[0].deviceId;
    setCamerasState(replaceInputs(cameras));
    setSelectedCamera(currentCamera);
  };

  const setDefaultMicrophone = (microphones) => {
    const defaultMicro = microphones.find((micro) => {
      return micro.deviceId === 'default';
    });
    setMicrophonesState(replaceInputs(microphones));
    if (defaultMicro) {
      setSelectedMicrophone(defaultMicro.deviceId);
    }
  };

  const setMicrophoneFromConstraints = (constraints) => {
    const currentMicrophone = constraints?.audio?.deviceId?.exact;
    if (currentMicrophone) {
      setSelectedMicrophone(currentMicrophone);
    }
  };

  const startDeviceManager = (handleDeviceManagerEvent) => {
    const manager = new DeviceManager();
    setDeviceManager(manager);
    manager.onChange(handleDeviceManagerEvent);
    manager.start();
  };

  const stopDeviceManager = (handleDeviceManagerEvent) => {
    deviceManager.removeListener(handleDeviceManagerEvent);
    deviceManager.stop();
    setDeviceManager(null);
  };

  return (
    <StreamSettingsContext.Provider
      value={{
        changeCamera,
        changeMicrophone,
        setDefaultCamera,
        setDefaultMicrophone,
        setMicrophoneFromConstraints,
        startDeviceManager,
        stopDeviceManager,
        selectedMicrophone,
        selectedCamera,
        camerasState,
        microphonesState,
        deviceManager,
      }}
      {...props}
    />
  );
}

function useStreamSettings() {
  const context = React.useContext(StreamSettingsContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { StreamSettingsProvider, useStreamSettings, StreamSettingsContext };
