const eventTypes = {
  connection: 'connection',
  disconnect: 'disconnect',
  reconnect: 'reconnect',
  podium: 'podium',
  room_setup: 'room_setup',

  // from this event u can get data about room
  room_ready: 'room_ready',
  fetch_room: 'fetch_room',
  accept: 'accept',
  stream_update: 'stream_update',
  change_stream: 'change_stream',

  //After a presentation ends, re-start the local stream with the previously active options.
  //It can also be used after devices are changed.
  start_stream: 'start_stream',

  start_recording: 'start_recording',
  stop_recording: 'stop_recording',

  //Received when the rooms recording state is updated.
  recording_update: 'recording_update',

  start_screen_capture: 'start_screen_capture',
  presentation_ended: 'presentation_ended',
  stop_presenting: 'stop_presenting',

  //Receive a chat message.
  chat: 'chat',

  //Send a chat message.
  send_chat: 'send_chat',

  //A new user has joined the eyeson room.
  add_user: 'add_user',

  //A user has left the eyeson room.
  remove_user: 'remove_user',

  //end meeting for all participants
  terminate_meeting: 'terminate_meeting',

  error: 'error',
  warning: 'warning',
  exit: 'exit',
};

export default eventTypes;
