import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import {
  AuthMenu,
  CustomAppBar,
  Logout,
  Menu,
  MenuItem,
  MenuLink,
  Navigation,
  UserAvatar,
} from './styled';
import Logo from '../Logo';

import { useAuth } from '../../context/auth';
import { useAppRouter } from '../../router/hooks/useAppRouter';

const Header = () => {
  const { user, logout } = useAuth();
  const { authMenu, menu } = useAppRouter();
  const { t } = useTranslation();

  const navigation = useMemo(() => {
    return (
      <Navigation>
        <Menu>
          {menu.map((item) => {
            const { name, path } = item;
            return (
              <MenuItem key={name}>
                <MenuLink type="link" to={path}>
                  {t(`menu.${name}`)}
                </MenuLink>
              </MenuItem>
            );
          })}
        </Menu>
      </Navigation>
    );
  }, [menu]);

  const authorization = useMemo(() => {
    return (
      <AuthMenu>
        {user ? (
          <>
            <Tooltip arrow title={`${user.firstName} ${user.lastName}`}>
              <UserAvatar alt="user-avatar">{`${user.firstName[0]}${user.lastName[0]}`}</UserAvatar>
            </Tooltip>
            <Logout onClick={logout}>{t('common.button.logout')}</Logout>
          </>
        ) : (
          <>
            {authMenu.map((item) => {
              const { name, path } = item;
              return (
                <MenuItem key={name}>
                  <MenuLink type="link" to={path}>
                    {t(`menu.${name}`)}
                  </MenuLink>
                </MenuItem>
              );
            })}
          </>
        )}
      </AuthMenu>
    );
  }, [user]);

  return (
    <CustomAppBar position="static">
      <Logo />
      {navigation}
      {authorization}
    </CustomAppBar>
  );
};

export default Header;
