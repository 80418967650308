import axios from 'axios';
import { clearTokens, getTokens } from '../../tokens/tokens';

const apiUrl = process.env.REACT_APP_BASE_URL ?? 'https://meetings.dev2.cogniteq.com/api';
const eyesonUrl = process.env.REACT_APP_EYESON_URL ?? 'https://api.eyeson.team';

//todo base url
const appInstance = axios.create({
  baseURL: apiUrl,
});

const eyesonInstance = axios.create({
  baseURL: eyesonUrl,
});

export default {
  app: appInstance,
  eyeson: eyesonInstance,
};
// check token and add it to requests
appInstance.interceptors.request.use((config) => {
  const accessToken = getTokens().accessToken;
  return accessToken
    ? {
        ...config,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    : config;
});
// need to refactor, now we listen local storage to delete user information after deleting tokens
appInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log(err);
    if (err.response.status === 401) {
      clearTokens();
    }
  },
);
