import axiosInstances from '../../utils/api/axiosInstances';

const login = (data) => {
  return axiosInstances.app.post('/login/email', data);
};

const signUp = (data) => {
  return axiosInstances.app.post('/auth/email', data);
};

const refreshToken = (refreshToken) => {
  return axiosInstances.app.post('/auth/refresh-token', { refreshToken });
};

const me = () => {
  return axiosInstances.app.get('/users/me');
};
const auth = {
  login,
  signUp,
  refreshToken,
  me,
};

export default auth;
