export const menu = [
  {
    name: 'home',
    title: 'Home',
    path: '/',
    private: false,
    isForAuth: false,
  },
  {
    name: 'roomManagement',
    title: 'Room management',
    path: '/room-management',
    private: true,
    isForAuth: false,
  },
  {
    name: 'signIn',
    title: 'Sign in',
    path: '/sign-in',
    private: false,
    isForAuth: true,
  },
  {
    name: 'signUp',
    title: 'Sign up',
    path: '/sign-up',
    private: false,
    isForAuth: true,
  },
];
