const layouts = {
  auto: {
    name: 'auto',
    users: null,
  },
  one: {
    name: 'one',
    users: [''],
  },
  two: {
    name: 'two',
    users: ['', ''],
  },
  four: {
    name: 'four',
    users: ['', '', '', ''],
  },
  six: {
    name: 'six',
    users: ['', '', '', '', '', ''],
  },
  nine: {
    name: 'nine',
    users: ['', '', '', '', '', '', '', '', ''],
  },
  'present-lower-3': {
    name: 'present-lower-3',
    users: ['', '', '', ''],
  },
  'present-upper-6': {
    name: 'present-upper-6',
    users: ['', '', '', '', '', '', ''],
  },
  'present-two-upper-6': {
    name: 'present-two-upper-6',
    users: ['', '', '', '', '', '', '', ''],
  },
  'present-upper-right-9': {
    name: 'present-upper-right-9',
    users: ['', '', '', '', '', '', '', '', ''],
  },
  'present-one-bg': {
    name: 'present-one-bg',
    users: ['playback', ''],
  },
  'present-center-four-bg': {
    name: 'present-center-four-bg',
    users: ['', '', '', ''],
  },
  'present-upper-3-bg': {
    name: 'present-upper-3-bg',
    users: ['playback', '', '', ''],
  },
  'mobile-center-8': {
    name: 'mobile-center-8',
    users: [
      'playback',
      '',
      '',
      'placeholder2',
      'placeholder3',
      '',
      '',
      'placeholder4',
      'placeholder5',
    ],
    voice_activation: true,
    show_names: false,
  },
  experimental: {
    name: 'experimental',
    users: ['playback', '', '', '', '', '', '', '', '', ''],
  },
};

export default layouts;
