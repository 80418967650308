import styled from 'styled-components';
import { TextField as MuiTextField } from '@mui/material';

export const PopoverContainer = styled.div`
  //height: 100%;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 600px;
  width: 300px;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;

  width: 100%;
  min-height: 200px;
  padding: 12px;

  border-radius: 4px 4px 0 0;
  background-color: #eaeaea;
`;

export const Message = styled.span`
  position: relative;

  width: 70%;
  padding: 8px 12px;
  margin-bottom: 24px;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.secondary.light};

  word-wrap: break-word;
  line-height: 120%;

  &::after {
    position: absolute;
    top: 100%;
    left: 0;

    content: ${({ userInfo }) => `"${userInfo}"`};

    height: 20px;

    font-size: 12px;
    color: #898989;
  }
`;

export const OwnMessage = styled(Message)`
  align-self: flex-end;
  background-color: ${({ theme }) => theme.palette.primary.light};

  &::after {
    right: 0;
    left: auto;
  }
`;

export const TypeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 12px;
`;

export const TextField = styled(MuiTextField)`
  width: 100%;
  margin-right: 8px;

  & textarea {
    font-size: 14px;
    line-height: 120%;
  }
`;
