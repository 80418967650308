import React from 'react';
import IconButton from '../IconButton';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ButtonsBottom, CustomCard, CustomContent, CustomModal } from './styled';
// Custom modal container
const Modal = ({
  apply,
  icon,
  children,
  title,
  applyDisabled,
  iconTitle,
  openHandler,
  closeHandler,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const onOpen = () => {
    // openHandler props used for settings and if you need to add additional logic to open
    if (openHandler) {
      openHandler();
    }
    setOpen(true);
  };

  const close = () => {
    if (closeHandler) {
      //closeHandler used for settings and if you need to add additional logic to open
      closeHandler();
    }
    setOpen(false);
  };

  const onApply = () => {
    apply();
    close();
  };

  return (
    <>
      <IconButton title={iconTitle} onClick={onOpen}>
        {icon}
      </IconButton>
      {open && (
        <CustomModal open={true}>
          <CustomCard>
            <CustomContent>
              <Typography>{title}</Typography>
              {children}
              <ButtonsBottom>
                <Button variant={'outlined'} onClick={close}>
                  {t('common.button.cancel')}
                </Button>
                <Button disabled={applyDisabled} variant={'contained'} onClick={onApply}>
                  {t('common.button.apply')}
                </Button>
              </ButtonsBottom>
            </CustomContent>
          </CustomCard>
        </CustomModal>
      )}
    </>
  );
};

export default Modal;
