import styled from 'styled-components';

export const Layout = styled.div`
  position: ${(props) => (props.fullPage ? 'fixed' : 'absolute')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
`;
