import React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import homeSchema from './HomeForm.yup';
import { Field, Formik } from 'formik';
import { CustomForm } from '../styled';

const idLength = 36;

const HomeForm = ({ handleChange }) => {
  const schema = homeSchema();
  const initialValues = homeSchema().cast({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const join = (values) => {
    const { id } = values;
    navigate(`/rooms/${id}`);
  };

  return (
    <>
      <Typography>{t('home.welcomeText')}</Typography>
      <Formik validationSchema={schema} initialValues={initialValues} onSubmit={join}>
        {({ setFieldValue, values }) => (
          <CustomForm>
            <Field
              required
              name={'id'}
              size={'small'}
              component={TextField}
              label={t('common.input.label.id')}
              helperText={t('common.input.helperText.id')}
              onChange={handleChange('id', setFieldValue)}
            />
            <Button disabled={values.id.length !== idLength} variant={'contained'} type={'submit'}>
              {t('common.button.join')}
            </Button>
          </CustomForm>
        )}
      </Formik>
    </>
  );
};

export default HomeForm;
