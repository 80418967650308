import styled from 'styled-components';
import { CardContent } from '@mui/material';
import { Form } from 'formik';

const CustomCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 480px;
  padding: 24px;
`;

const CustomForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export { CustomCardContent, CustomForm, Container };
