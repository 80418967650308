import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/auth';

const PrivateRoute = (props) => {
  const { children } = props;
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
