import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const PopoverContent = styled.div`
  padding: 12px 24px;
  display: flex;
  gap: 24px;
  align-items: flex-start;
`;

const Text = styled.div`
  font-size: 12px;
  color: gray;
`;

const Url = styled.input`
  border: 0;
  caret-color: transparent;
  font-size: 16px;
  width: 350px;
  text-decoration: underline;

  &:focus {
    outline: none;
  }
`;

const LinkContainer = styled.div`
  border: 2px solid gray;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4px 16px;
`;

export { LinkContainer, Url, Container, Text, PopoverContent };
