import axiosInstances from '../../utils/api/axiosInstances';
const changeLayout = (access_key, options = { layout: 'auto' }) => {
  return axiosInstances.eyeson.post(`/rooms/${access_key}/layout`, options);
};

const addLayer = (access_key, options) => {
  return axiosInstances.eyeson.post(`/rooms/${access_key}/layers`, options);
};

const addPlayback = (access_key, options) => {
  return axiosInstances.eyeson.post(`/rooms/${access_key}/playbacks`, options);
};

const deletePlayback = (access_key) => {
  return axiosInstances.eyeson.delete(`/rooms/${access_key}/playbacks/playback`);
};

const deleteTopLayer = (access_key) => {
  return axiosInstances.eyeson.delete(`/rooms/${access_key}/layers/1`);
};

const deleteBottomLayer = (access_key) => {
  return axiosInstances.eyeson.delete(`/rooms/${access_key}/layers/-1`);
};

const getRoom = (access_key) => {
  return axiosInstances.eyeson.get(`/rooms/${access_key}`);
};

const eyeson = {
  changeLayout,
  addLayer,
  addPlayback,
  deletePlayback,
  deleteTopLayer,
  deleteBottomLayer,
  getRoom,
};

export default eyeson;
