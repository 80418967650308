import styled from 'styled-components';
import { Card, CardContent, Modal } from '@mui/material';

const CustomModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomCard = styled(Card)`
  max-width: 600px;
  min-width: 300px;
`;

const CustomContent = styled(CardContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

const ButtonsBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`;

export { CustomModal, CustomCard, CustomContent, ButtonsBottom };
