import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Chat as ChatIcon, Send } from '@mui/icons-material';
import { Popover } from '@mui/material';

import {
  ChatContainer,
  Message,
  MessagesContainer,
  OwnMessage,
  PopoverContainer,
  TextField,
  TypeContainer,
} from './styled';
import IconButton from '../../IconButton';

const Chat = (props) => {
  const { chatMessages, sendChatMessage, name } = props;
  const { t } = useTranslation();

  const chatIconRef = React.useRef();
  const [chatShown, setChatShown] = React.useState(false);
  const [chatMessage, setChatMessage] = useState('');

  const typeMessageHandler = (e) => {
    setChatMessage(e.target.value);
  };

  const sendMessageHandler = () => {
    if (chatMessage.trim().length === 0) {
      setChatMessage('');
      return;
    }

    sendChatMessage(chatMessage);
    setChatMessage('');
  };

  const showChatPopover = () => {
    setChatShown(true);
  };

  const closeChatPopover = () => {
    setChatShown(false);
  };

  const messagesElements = useMemo(() => {
    return chatMessages.map((mess, index) => {
      const { user, timestamp: date, content: text } = mess;
      const userInfo = `${user.name} at ${DateTime.fromJSDate(date).toFormat('HH:mm:ss')}`;

      if (user.name === name) {
        return (
          <OwnMessage userInfo={userInfo} key={index}>
            {text}
          </OwnMessage>
        );
      } else {
        return (
          <Message userInfo={userInfo} key={text}>
            {text}
          </Message>
        );
      }
    });
  }, [chatMessages, name]);

  return (
    <>
      <IconButton onClick={showChatPopover} title={t('common.iconButton.showChat')}>
        <ChatIcon fontSize={'large'} ref={chatIconRef} />
      </IconButton>

      <Popover
        open={chatShown}
        anchorEl={chatIconRef.current}
        onClose={closeChatPopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <PopoverContainer>
          <ChatContainer>
            <MessagesContainer>{messagesElements}</MessagesContainer>

            <TypeContainer>
              <TextField
                multiline
                rows={3}
                size={'small'}
                placeholder={t('meetingRoom.chat.textPlaceholder')}
                value={chatMessage}
                onChange={typeMessageHandler}
              />

              <IconButton onClick={sendMessageHandler}>
                <Send color="primary" />
              </IconButton>
            </TypeContainer>
          </ChatContainer>
        </PopoverContainer>
      </Popover>
    </>
  );
};

export default Chat;
