const globalStyles = `
  p {
    margin: 0;
  }

  ul, ol, li {
    margin: 0;
    padding: 0;
  }
`;

export default globalStyles;
