import React, { useState } from 'react';
import eyeson from 'eyeson/index';
import { useTranslation } from 'react-i18next';
import { SettingsApplications, VideocamOff } from '@mui/icons-material';

import { Video, VideoContainer, VideoPlaceholder } from './styled';
import SettingsSelect from '../../SettingsSelect';
import Modal from '../../Modal';
import eventTypes from '../../../constants/eventTypes';
import { useStreamSettings } from '../../../context/streamSettings';

const Settings = ({ streamState }) => {
  const { t } = useTranslation();
  const {
    changeCamera,
    changeMicrophone,
    setDefaultCamera,
    setDefaultMicrophone,
    setMicrophoneFromConstraints,
    selectedMicrophone,
    selectedCamera,
    camerasState,
    microphonesState,
    startDeviceManager,
    stopDeviceManager,
    deviceManager,
  } = useStreamSettings();
  const video = React.useRef(null);
  const [stream, setStream] = useState(null);

  // update stream with new settings
  const onApply = React.useCallback(() => {
    if (!streamState.screen) {
      eyeson.send({
        type: eventTypes.start_stream,
        audio: streamState.audio,
        video: streamState.video,
      });
    }
    deviceManager.storeConstraints();
  }, [streamState]);
  //start checking devices
  const openHandler = () => {
    startDeviceManager(handleDeviceManagerEvent);
  };
  // stop checking devices
  const closeHandler = () => {
    stopDeviceManager(handleDeviceManagerEvent);
  };

  // works two times when device manager started
  // first you receive devices, second options
  const handleDeviceManagerEvent = (e) => {
    const { cameras, microphones, error, stream, constraints } = e;
    if (cameras?.length) {
      setDefaultCamera(cameras);
    }

    if (microphones?.length) {
      setDefaultMicrophone(microphones);
    }

    if (constraints) {
      setMicrophoneFromConstraints(constraints);
    }

    if (stream) {
      video.current.srcObject = stream;
      setStream(stream);
    }

    if (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      applyDisabled={!selectedMicrophone || !selectedCamera}
      apply={onApply}
      icon={<SettingsApplications fontSize={'large'} />}
      iconTitle={t('common.iconButton.settings')}
      openHandler={openHandler}
      closeHandler={closeHandler}
    >
      <VideoContainer>
        <Video
          stream={stream}
          style={{ transform: 'scale(-1,1)' }}
          ref={video}
          playsInline
          autoPlay
          muted
        />
        <VideoPlaceholder stream={stream}>
          <VideocamOff />
        </VideoPlaceholder>
      </VideoContainer>
      <SettingsSelect
        onChangeValue={changeMicrophone}
        selectedValue={selectedMicrophone}
        labelText={t('common.select.label.microphone')}
        options={microphonesState}
        disabled={!selectedMicrophone}
        labelId={'microphone-label'}
      />
      <SettingsSelect
        onChangeValue={changeCamera}
        selectedValue={selectedCamera}
        labelText={t('common.select.label.camera')}
        options={camerasState}
        disabled={!stream || !camerasState.length}
        labelId={'camera-label'}
      />
    </Modal>
  );
};

export default Settings;
