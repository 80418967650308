import styled from 'styled-components';

const SettingsBar = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
const PlayerContainer = styled.div`
  width: 800px;
`;

const Video = styled.video`
  width: 100%;
  min-height: 400px;
`;

export { SettingsBar, Video, PlayerContainer };
