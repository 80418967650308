import formatQueryParams from '../../utils/api/format-query-params';
import axiosInstances from '../../utils/api/axiosInstances';

const getRooms = (params) => {
  const queryParams = formatQueryParams(params);

  return axiosInstances.app.get(`/rooms${queryParams && `?${queryParams}`}`);
};

const createRoom = () => {
  return axiosInstances.app.post('/rooms');
};

const updateRoom = (id, data) => {
  return axiosInstances.app.post(`/rooms/${id}`, data);
};

const initRoomSession = (data) => {
  return axiosInstances.app.post('/rooms/init', data);
};

const rooms = {
  getRooms,
  createRoom,
  updateRoom,
  initRoomSession,
};

export default rooms;
