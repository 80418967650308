import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;

  background: #000000;
`;

export const Copyright = styled.p`
  font-size: 14px;
  color: white;
`;
