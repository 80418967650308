import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';

import { LayoutContainer, Main } from './styled';
import { Footer, Header, Loader } from '../../components';

import { useAuth } from '../../context/auth';
import { useAppRouter } from '../hooks/useAppRouter';
import { PrivateRoute, PublicRoute } from '../components';

export const AppLayout = () => {
  const { t } = useTranslation();
  const { pages } = useAppRouter();
  const { isInitializing } = useAuth();

  return (
    <LayoutContainer>
      <Header />

      <Main>
        {isInitializing ? (
          <Loader fullPage />
        ) : (
          <Routes>
            {pages.map((item) => {
              const { name, component: Component, path, private: isPrivate, index } = item;
              let RouteWrapper;

              if (isPrivate) {
                RouteWrapper = PrivateRoute;
              } else {
                RouteWrapper = PublicRoute;
              }

              return (
                <Route
                  key={name}
                  path={path}
                  index={index}
                  element={
                    <RouteWrapper page={item}>
                      <Helmet title={t(`pages.${name}`)} />
                      <Component />
                    </RouteWrapper>
                  }
                />
              );
            })}
          </Routes>
        )}
      </Main>

      <Footer />
    </LayoutContainer>
  );
};
