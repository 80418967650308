import styled from 'styled-components';

const Video = styled.video`
  width: 100%;
  min-height: 350px;
  margin: 0 auto;
  display: ${(props) => (props.stream ? 'block' : 'none')};
`;

const VideoPlaceholder = styled.div`
  display: ${(props) => (props.stream ? 'none' : 'flex')};
  width: 100%;
  justify-content: center;
  align-items: center;
  background: gray;
  height: 350px;
`;

const VideoContainer = styled.div`
  width: 100%;
`;

export { Video, VideoPlaceholder, VideoContainer };
