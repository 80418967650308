import auth from './auth/auth';
import rooms from './rooms';
import eyeson from './eyeson';
//eyeson use another instance
const api = {
  auth,
  rooms,
  eyeson,
};

export default api;
