import React from 'react';

import FormLayout from '../../components/Forms';
import HomeForm from '../../components/Forms/EnterRoomForm';

const Home = () => {
  return <FormLayout component={HomeForm} />;
};

export default Home;
