import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TableContainer } from '@mui/material';

export const CustomTable = styled(TableContainer)`
  margin: 0 auto;
  max-width: 700px;
`;

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px;
  align-self: flex-start;
  margin-top: 24px;
`;

export const CustomLink = styled(Link)`
  color: black;
`;
