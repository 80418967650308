import React from 'react';
import { useTranslation } from 'react-i18next';
import { LayersOutlined } from '@mui/icons-material';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import Modal from '../../Modal';
import layouts from '../../../constants/layouts';
import layers from '../../../constants/layers';
import api from '../../../services';

const Layers = ({ accessKey, layer, layout, setLayout, setLayer }) => {
  const { t } = useTranslation();

  const onChangeLayer = (e) => {
    const value = e.currentTarget.value;
    setLayout((prevState) => {
      return {
        ...prevState,
        ...layouts[value],
      };
    });
    setLayer((prevState) => {
      return {
        ...prevState,
        ...layers[value],
      };
    });
  };

  const addLayer = async (options) => {
    try {
      if (!options) {
        return;
      }
      await api.eyeson.addLayer(accessKey, options);
    } catch (err) {
      console.log(err);
    }
  };

  const addPlayback = async (options) => {
    try {
      //delete playback in all cases because we cant get information about current playback
      //but to add new playback need to delete previous
      await Promise.allSettled([api.eyeson.deletePlayback(accessKey)]);
      if (!options) {
        return;
      }
      await api.eyeson.addPlayback(accessKey, options);
    } catch (err) {
      console.log(err);
    }
  };

  const onApply = async () => {
    const { bottomLayer, topLayer, playback } = layer;
    try {
      await api.eyeson.changeLayout(accessKey, layout);
      await Promise.allSettled([addLayer(bottomLayer), addLayer(topLayer), addPlayback(playback)]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      title={t('meetingRoom.layers.title')}
      apply={onApply}
      icon={<LayersOutlined fontSize={'large'} />}
      iconTitle={t('common.iconButton.changeLayer')}
    >
      <FormControl>
        <RadioGroup aria-labelledby="layer" value={layout.name} onChange={onChangeLayer}>
          <FormControlLabel
            value={layouts.auto.name}
            control={<Radio />}
            label={t('meetingRoom.layers.auto')}
          />
          <FormControlLabel
            value={layouts['present-one-bg'].name}
            control={<Radio />}
            label={t('meetingRoom.layers.news')}
          />
          <FormControlLabel
            value={layouts['present-center-four-bg'].name}
            control={<Radio />}
            label={t('meetingRoom.layers.panelDiscussion')}
          />
          <FormControlLabel
            value={layouts['present-upper-3-bg'].name}
            control={<Radio />}
            label={t('meetingRoom.layers.watchParty')}
          />
          <FormControlLabel
            value={layouts['mobile-center-8'].name}
            control={<Radio />}
            label={t('meetingRoom.layers.mobileGameplay')}
          />
          <FormControlLabel
            value={layouts.experimental.name}
            control={<Radio />}
            label={t('meetingRoom.layers.onlineLecture')}
          />
        </RadioGroup>
      </FormControl>
    </Modal>
  );
};

export default Layers;
