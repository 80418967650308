import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCopy, PersonAdd } from '@mui/icons-material';
import { Popover } from '@mui/material';

import { Container, LinkContainer, PopoverContent, Text, Url } from './styled';
import IconButton from '../../IconButton';

const InviteGuest = () => {
  const copyRef = React.useRef();
  const { t } = useTranslation();
  const url = window.location.href;
  const [copied, setCopied] = React.useState(false);
  const [showLink, setShowLink] = React.useState(false);

  const openPopover = () => {
    setShowLink(true);
  };

  const closePopover = () => {
    setShowLink(false);
    setCopied(false);
  };

  const selectTextInInput = (e) => {
    e.target.select();
  };

  const onCopyClick = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => setCopied(true))
      .catch((err) => {
        console.log(err);
      });
  };

  //TODO common iconButton
  return (
    <>
      <IconButton onClick={openPopover} title={t('common.iconButton.inviteGuest')}>
        <PersonAdd ref={copyRef} fontSize={'large'} />
      </IconButton>

      <Popover
        open={showLink}
        anchorEl={copyRef.current}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <PopoverContent>
          <Container>
            <LinkContainer>
              <Text>{t('meetingRoom.inviteGuest.copyLink')}</Text>
              <Url value={url} readOnly onFocus={selectTextInInput} />
            </LinkContainer>
            {copied && <Text>{t('meetingRoom.inviteGuest.copiedToClipboard')}</Text>}
          </Container>
          <IconButton onClick={onCopyClick} title={t('meetingRoom.inviteGuest.copyLink')}>
            <ContentCopy fontSize={'large'} />
          </IconButton>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default InviteGuest;
