import styled from 'styled-components';
import { CardContent } from '@mui/material';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  min-width: 390px;
`;

const Error = styled.p`
  color: red;
`;

const Video = styled.video`
  width: 560px;
  height: 420px;
  margin: 0 auto;
`;

const VideoContainer = styled.div`
  width: 100%;
`;

const CustomCardContent = styled(CardContent)`
  display: flex;
  gap: 24px;
`;

const IconButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const ButtonWithLoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Content,
  Error,
  Video,
  VideoContainer,
  CustomCardContent,
  IconButtonsContainer,
  ButtonWithLoaderContainer,
};
