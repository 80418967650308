import React from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { CustomIconButton } from './styled';
// icon button with tooltip
const IconButton = ({ onClick, title = '', disabled = false, children }) => {
  return (
    <Tooltip TransitionComponent={Zoom} title={title} arrow>
      {/*div here needed to avoid problems with disabled button*/}
      <div>
        <CustomIconButton disabled={disabled} onClick={onClick}>
          {children}
        </CustomIconButton>
      </div>
    </Tooltip>
  );
};

export default IconButton;
