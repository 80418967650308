import React from 'react';
import { useNavigate } from 'react-router-dom';

import { clearTokens, getTokens } from '../utils/tokens/tokens';
import api from '../services';

const AuthContext = React.createContext({});

function AuthProvider(props) {
  const navigate = useNavigate();

  const [user, setUser] = React.useState(null);
  const [isInitializing, setIsInitializing] = React.useState(true);

  const initializeApp = async () => {
    try {
      await getUserData();
    } catch (err) {
      logout();
      console.log(err);
    } finally {
      setIsInitializing(false);
    }
  };

  const logout = React.useCallback(() => {
    clearTokens();
    setUser(null);
    navigate('/');
  }, []);

  React.useEffect(() => {
    const tokens = getTokens();
    if (tokens.accessToken && tokens.refreshToken) {
      initializeApp();
    } else {
      setIsInitializing(false);
    }
  }, []);

  const getUserData = React.useCallback(async () => {
    try {
      const { data, error } = await api.auth.me();
      if (error) {
        console.log(error);
        return;
      }

      if (data) {
        setUser(data.data);
      }
    } catch (err) {
      //TODO error handling
      console.log(err);
    }
  }, []);
  // listen local storage changing and if tokens was deleted do logout
  // when you get 401 error interceptor delete tokens
  // maybe need to create global variable or add redux to store user data
  React.useEffect(() => {
    const storageListener = () => {
      const tokens = getTokens();
      if (!tokens.refreshToken || !tokens.accessToken) {
        setUser(null);
        navigate('/');
      }
    };

    window.addEventListener('storage', storageListener);
    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isInitializing,
        user,
        setUser,
        logout,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth, AuthContext };
