import React from 'react';
import { Helmet } from 'react-helmet';

import { AppLayout } from './router/layouts/AppLayout';

const App = () => {
  return (
    <>
      <Helmet defaultTitle="Cogniteq meetings" titleTemplate="%s - Cogniteq meetings" />
      <AppLayout />
    </>
  );
};

export default App;
