import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Link } from '@mui/material';

const MuiNavLink = (props) => {
  const { type = 'link', to = '#', children, ...rest } = props;

  const map = {
    button: (
      <Button component={NavLink} to={to} {...rest}>
        {children}
      </Button>
    ),
    link: (
      <Link component={NavLink} to={to} {...rest}>
        {children}
      </Link>
    ),
  };

  return map[type];
};

export default MuiNavLink;
