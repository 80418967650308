import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/auth';

const PublicRoute = (props) => {
  const { children, page } = props;
  const location = useLocation();
  const { user } = useAuth();

  if (user && page.onlyNonAuth) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default PublicRoute;
