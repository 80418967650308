import React from 'react';
import { Button, Card, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Mic, MicOff, Videocam, VideocamOff, VolumeOff, VolumeUp } from '@mui/icons-material';
import eyeson from 'eyeson/index';
import { useParams } from 'react-router-dom';

import {
  Content,
  Error,
  VideoContainer,
  Video,
  CustomCardContent,
  IconButtonsContainer,
  ButtonWithLoaderContainer,
} from './styled';
import SettingsSelect from '../SettingsSelect';
import Loader from '../Loader';
import api from '../../services';
import useEyesonEvents from '../../utils/hooks/useEyesonEvents';
import eventTypes from '../../constants/eventTypes';
import { useStreamSettings } from '../../context/streamSettings';
import IconButton from '../IconButton';

const EnteringRoom = ({
  audio,
  video,
  setStreamState,
  setAccessKey,
  setIsCreator,
  name,
  setName,
  isLoading,
  setIsLoading,
}) => {
  //TODO duplicate logic mb create context for local stream changes
  const { t } = useTranslation();
  const { id } = useParams();
  const videoRef = React.useRef(null);
  const {
    changeCamera,
    changeMicrophone,
    setDefaultCamera,
    setDefaultMicrophone,
    setMicrophoneFromConstraints,
    selectedMicrophone,
    selectedCamera,
    camerasState,
    microphonesState,
    startDeviceManager,
    stopDeviceManager,
    deviceManager,
  } = useStreamSettings();
  //local stream state
  const [stream, setStream] = React.useState(null);
  //stream muted or not
  const [isMuted, setIsMuted] = React.useState(true);
  // input error
  const [nameError, setNameError] = React.useState('');
  //todo need to do one state for room and permissions errors
  const [roomError, setRoomError] = React.useState('');
  const [permissionsError, setPermissionsError] = React.useState(false);

  const onNameChange = React.useCallback((e) => {
    setName(e.target.value);
  }, []);

  const handleConnectionEvent = (event) => {
    if (event.connectionStatus === 'access_denied') {
      setIsLoading(false);
      setRoomError('access denied');
    }
  };
  // handle camera and microphone permissions error
  const handlePermissionsError = (event) => {
    if (event.name === 'permission') {
      setPermissionsError(true);
    }
  };

  // function to start session
  const start = React.useCallback((key) => {
    setRoomError('');
    setStreamState((prevState) => {
      eyeson.start(key, { audio: prevState.audio, video: prevState.video });
      return { ...prevState, connecting: true };
    });
  }, []);

  // init session on our api, in response get data for start, start session
  const createSession = React.useCallback(async () => {
    setIsLoading(true);
    setNameError('');
    setPermissionsError(false);
    try {
      const { data } = await api.rooms.initRoomSession({
        id: id,
        name: name,
      });
      setIsCreator(data.data.isCreator);
      if (data) {
        setAccessKey(data.data.accessKey);
        start(data.data.accessKey);
      }
    } catch (err) {
      setIsLoading(false);
      setNameError(err.response.data.error);
    }
  }, [name]);
  // store current devices(camera,microphone) and enables or not
  // and start create session
  const initSession = () => {
    deviceManager.storeConstraints();
    createSession();
  };
  // check microphone on local stream
  const checkAudio = () => {
    setIsMuted((prevState) => !prevState);
  };

  const toggleLocalCamera = () => {
    deviceManager.updateWithOptions({
      audio: audio,
      eco: false,
      video: !video,
    });
  };
  //todo bug when reselect device with disabled micro
  const toggleLocalAudio = () => {
    deviceManager.updateWithOptions({
      audio: !audio,
      eco: false,
      video: video,
    });
  };

  // works two times when device manager started
  // first you receive devices, second options
  const handleDeviceManagerEvent = (e) => {
    const { cameras, microphones, error, stream, constraints, options } = e;
    if (cameras?.length) {
      setDefaultCamera(cameras);
    }

    if (microphones?.length) {
      setDefaultMicrophone(microphones);
    }

    if (constraints) {
      setMicrophoneFromConstraints(constraints);
    }

    if (options) {
      setStreamState((prevState) => {
        return { ...prevState, audio: options.audio, video: options.video };
      });
    }

    if (stream) {
      videoRef.current.srcObject = stream;
      setStream(stream);
    }

    if (error) {
      setPermissionsError(error.message);
    }
  };

  useEyesonEvents({
    [eventTypes.connection]: handleConnectionEvent,
    [eventTypes.error]: handlePermissionsError,
  });
  // store options and devices after every device change
  React.useEffect(() => {
    if (selectedMicrophone && selectedCamera && deviceManager) {
      deviceManager.storeConstraints();
    }
  }, [selectedMicrophone, selectedCamera]);

  //start and clearing device manager

  React.useEffect(() => {
    if (!deviceManager) {
      startDeviceManager(handleDeviceManagerEvent);
    }
  }, []);

  //it's needed to stop local stream and disable checking devices
  React.useEffect(() => {
    return () => {
      if (deviceManager) {
        stopDeviceManager(handleDeviceManagerEvent);
      }
    };
  }, [deviceManager]);

  return (
    <Card>
      <CustomCardContent>
        <VideoContainer>
          <Video
            video={video}
            style={{ transform: 'scale(-1,1)' }}
            ref={videoRef}
            playsInline
            autoPlay
            muted={isMuted}
            poster={'/logo192.png'}
          />
        </VideoContainer>
        <Content>
          {permissionsError && (
            <Typography color={'red'}>{t('meetingRoom.permissionsError')}</Typography>
          )}
          <SettingsSelect
            onChangeValue={changeMicrophone}
            selectedValue={selectedMicrophone}
            labelText={t('common.select.label.microphone')}
            options={microphonesState}
            disabled={!selectedMicrophone}
            labelId={'microphone-label'}
          />
          <SettingsSelect
            onChangeValue={changeCamera}
            selectedValue={selectedCamera}
            labelText={t('common.select.label.camera')}
            options={camerasState}
            disabled={!selectedCamera}
            labelId={'camera-label'}
          />
          <IconButtonsContainer>
            <IconButton
              title={t('common.iconButton.toggleAudio')}
              onClick={toggleLocalAudio}
              disabled={!selectedMicrophone}
            >
              {audio ? <Mic fontSize={'large'} /> : <MicOff fontSize={'large'} />}
            </IconButton>
            <IconButton
              title={t('common.iconButton.toggleVideo')}
              onClick={toggleLocalCamera}
              disabled={!selectedCamera}
            >
              {video ? <Videocam fontSize={'large'} /> : <VideocamOff fontSize={'large'} />}
            </IconButton>
            <IconButton
              disabled={!stream}
              title={t('common.iconButton.checkMicrophone')}
              onClick={checkAudio}
            >
              {isMuted ? <VolumeOff fontSize={'large'} /> : <VolumeUp fontSize={'large'} />}
            </IconButton>
          </IconButtonsContainer>
          <p>{t('rooms.enterName')}</p>
          {Boolean(roomError) && <Error>{roomError}</Error>}
          <TextField
            label={t('common.input.label.name')}
            value={name}
            onChange={onNameChange}
            size={'small'}
            error={Boolean(nameError)}
            helperText={nameError ? nameError : t('common.input.helperText.name')}
          />
          <ButtonWithLoaderContainer>
            {isLoading ? (
              <Loader />
            ) : (
              <Button
                disabled={name.length < 4}
                fullWidth
                variant={'contained'}
                onClick={initSession}
              >
                {t('common.button.startSession')}
              </Button>
            )}
          </ButtonWithLoaderContainer>
        </Content>
      </CustomCardContent>
    </Card>
  );
};
export default EnteringRoom;
