import React from 'react';
import eyeson from 'eyeson/index';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../context/auth';
import eventTypes from '../../constants/eventTypes';
import EnteringRoom from '../../components/EnteringRoom';
import VideoPlayer from '../../components/VideoPlayer';
import { Container } from './styled';
import useEyesonEvents from '../../utils/hooks/useEyesonEvents';

const MeetingRoom = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const initialName = React.useMemo(() => {
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [user]);
  //session access key for requests
  const [accessKey, setAccessKey] = React.useState(null);
  // creator can change stream layouts, layers, start recording and end session for all participants
  const [isCreator, setIsCreator] = React.useState(false);
  const [streamState, setStreamState] = React.useState({
    localStream: null,
    remoteStream: null,
    connecting: false,
    audio: true,
    video: true,
    screen: false,
  });
  //name for entering room input
  const [name, setName] = React.useState(initialName);
  const [isLoading, setIsLoading] = React.useState(false);
  const commonProps = {
    audio: streamState.audio,
    video: streamState.video,
    setStreamState,
    name: name,
  };
  // leave session for user
  const endSession = React.useCallback(() => {
    eyeson.destroy();
    setStreamState((prevState) => {
      return {
        ...prevState,
        localStream: null,
        remoteStream: null,
        connecting: false,
      };
    });
  }, []);

  // on every stream update we need to set new stream from event
  const handleStreamUpdateEvent = (event) => {
    setStreamState((prevState) => {
      return {
        ...prevState,
        localStream: event.localStream ? event.localStream : prevState.localStream,
        remoteStream: event.stream,
      };
    });
  };

  //when presentation ended need to start stream with current audio/video parameters
  const handlePresentationEndedEvent = () => {
    setStreamState((prevState) => {
      eyeson.send({
        type: eventTypes.start_stream,
        audio: prevState.audio,
        video: prevState.video,
      });
      return { ...prevState, screen: false };
    });
  };

  //works when after connection when stream ready
  const handleAcceptEvent = (event) => {
    setStreamState((prevState) => {
      return {
        ...prevState,
        localStream: event.localStream,
        remoteStream: event.remoteStream,
        connecting: false,
      };
    });
    setIsLoading(false);
  };

  const handleWarningEvent = (event) => {
    console.log({ toastMessage: 'Warning: ' + event.name });
  };

  const handleErrorEvent = (event) => {
    console.log({ toastMessage: 'Error: ' + event.name });
    setIsLoading(false);
    endSession();
  };

  const handleExitEvent = () => {
    console.log({ toastMessage: 'Meeting has ended' });
    setIsLoading(false);
    navigate('/');
  };

  useEyesonEvents({
    [eventTypes.stream_update]: handleStreamUpdateEvent,
    [eventTypes.presentation_ended]: handlePresentationEndedEvent,
    [eventTypes.accept]: handleAcceptEvent,
    [eventTypes.warning]: handleWarningEvent,
    [eventTypes.error]: handleErrorEvent,
    [eventTypes.exit]: handleExitEvent,
  });

  React.useEffect(() => {
    return () => {
      endSession();
    };
  }, []);

  return (
    <Container>
      {!streamState.remoteStream && (
        <EnteringRoom
          setAccessKey={setAccessKey}
          setIsCreator={setIsCreator}
          setName={setName}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          {...commonProps}
        />
      )}
      {streamState.remoteStream && (
        <VideoPlayer
          accessKey={accessKey}
          isCreator={isCreator}
          screen={streamState.screen}
          remoteStream={streamState.remoteStream}
          endSession={endSession}
          {...commonProps}
        />
      )}
    </Container>
  );
};

export default MeetingRoom;
