import styled from 'styled-components';
import { IconButton } from '@mui/material';

const CustomIconButton = styled(IconButton)`
  position: relative;
  width: 60px;
  height: 60px;
  font-size: 20px;
`;

export { CustomIconButton };
