import React from 'react';
import ReactDOM from 'react-dom';
import initI18n from 'utils/i18n';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { AuthProvider } from './context/auth';
import { StreamSettingsProvider } from './context/streamSettings';
import App from './App';
import theme from './assets/theme';
import history from './router/history';

initI18n();

ReactDOM.render(
  <HistoryRouter history={history}>
    <AuthProvider>
      <StreamSettingsProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StreamSettingsProvider>
    </AuthProvider>
  </HistoryRouter>,
  document.getElementById('root'),
);
