import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-mui';
import { Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import loginSchema from './LoginForm.yup';
import { Field, Formik } from 'formik';
import PasswordField from '../../PasswordField';
import { useAuth } from '../../../context/auth';
import { CustomForm } from '../styled';
import api from '../../../services';
import { setTokens } from '../../../utils/tokens/tokens';

const LoginForm = ({ handleChange }) => {
  const schema = loginSchema();
  const initialValues = loginSchema().cast({});
  const { t } = useTranslation();
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginError, setLoginError] = React.useState(null);

  const login = React.useCallback(async (loginData) => {
    let pathToRedirect = location?.pathname || '/';
    setLoginError(null);

    try {
      const { data, error } = await api.auth.login(loginData);
      if (error) {
        //TODO error handling
        console.log(error);
        return;
      }
      if (data) {
        const accessToken = data.data.accessToken;
        const refreshToken = data.data.refreshToken;
        setUser(data.data.user);
        setTokens(accessToken, refreshToken);
        navigate(pathToRedirect, { replace: true });
      }
    } catch (err) {
      if (err.response) {
        setLoginError(err.response.data.error);
      }
    }
  }, []);

  return (
    <>
      <Typography>{t('login.title')}</Typography>
      {loginError && <Typography color={'red'}>{loginError}</Typography>}
      <Formik validationSchema={schema} initialValues={initialValues} onSubmit={login}>
        {({ isValid, dirty, setFieldValue, isSubmitting }) => (
          <CustomForm>
            <Field
              required
              name={'email'}
              size={'small'}
              component={TextField}
              label={t('common.input.label.email')}
              onChange={handleChange('email', setFieldValue)}
            />
            <Field
              required
              name={'password'}
              size={'small'}
              component={PasswordField}
              label={t('common.input.label.password')}
              onChange={handleChange('password', setFieldValue)}
            />
            <Button
              disabled={!(isValid && dirty) || isSubmitting}
              variant={'contained'}
              type={'submit'}
            >
              {t('common.button.login')}
            </Button>
          </CustomForm>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
