const layers = {
  'present-one-bg': {
    topLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/news-fg1.png',
    },
    bottomLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/news-bg.jpg',
      'z-index': '-1',
    },
    playback: {
      play_id: 'playback',
      url: 'https://storage.googleapis.com/eyeson-demo/videos/aerial-loop.webm',
      audio: true,
    },
  },
  'present-center-four-bg': {
    topLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/company-fg.png',
    },
    bottomLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/company-bg-ph.jpg',
      'z-index': '-1',
    },
    playback: null,
  },
  'present-upper-3-bg': {
    topLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/movie-fg.png',
    },
    bottomLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/movie-bg-ph.jpg',
      'z-index': '-1',
    },
    playback: {
      play_id: 'playback',
      url: 'https://storage.googleapis.com/eyeson-demo/videos/space.webm',
      audio: true,
    },
  },
  'mobile-center-8': {
    topLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/game-fg.png',
    },
    bottomLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/game-bg-ph2.jpg',
      'z-index': '-1',
    },
    playback: null,
  },
  experimental: {
    topLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/lecture-fg.png',
    },
    bottomLayer: {
      url: 'https://storage.googleapis.com/eyeson-demo/pictures/lecture-bg.jpg',
      'z-index': '-1',
    },
    playback: {
      play_id: 'playback',
      url: 'https://storage.googleapis.com/eyeson-demo/videos/school-loop.webm',
      audio: true,
    },
  },
};

export default layers;
