import React from 'react';
import eyeson from 'eyeson/index';

//hook that add event handlers to eyeson
//events : { [event_name]: event_handler, [event_name] : event_handler, ... }
const useEyesonEvents = (events) => {
  const handleEvent = React.useCallback(
    (event) => {
      Object.entries(events).forEach(([eventType, eventListener]) => {
        if (event.type === eventType) {
          eventListener(event);
        }
      });
    },
    [events],
  );

  React.useEffect(() => {
    eyeson.onEvent(handleEvent);

    return () => {
      eyeson.offEvent(handleEvent);
    };
  }, [handleEvent]);
};

export default useEyesonEvents;
