import React from 'react';
import { GridOn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Radio, RadioGroup, Switch } from '@mui/material';

import api from '../../../services';
import Modal from '../../Modal';
import layouts from '../../../constants/layouts';

const Layouts = ({ accessKey, setLayout, layout, layer, setLayer }) => {
  const { t } = useTranslation();
  // showing names on a stream
  const onChangeShowNames = (e) => {
    const checked = e.target.checked;
    setLayout((prevState) => {
      return { ...prevState, show_names: checked };
    });
  };
  // set new layout, and delete all layers
  const onApply = async () => {
    try {
      await api.eyeson.changeLayout(accessKey, layout);
      if (layer.bottomLayer || layer.topLayer || layer.playback) {
        await Promise.allSettled([
          api.eyeson.deleteTopLayer(accessKey),
          api.eyeson.deleteBottomLayer(accessKey),
          api.eyeson.deletePlayback(accessKey),
        ]);
      }
      setLayer({ bottomLayer: null, topLayer: null, playback: null });
    } catch (err) {
      console.log(err);
    }
  };

  const onChange = (e) => {
    const value = e.currentTarget.value;
    setLayout((prevState) => {
      return {
        ...prevState,
        ...layouts[value],
      };
    });
  };

  return (
    <Modal
      title={t('meetingRoom.layouts.title')}
      applyDisabled={!layout.name}
      apply={onApply}
      icon={<GridOn fontSize={'large'} />}
      iconTitle={t('common.iconButton.changeLayout')}
    >
      <FormControl>
        <RadioGroup
          aria-labelledby="layout"
          defaultValue={'auto'}
          value={layout.name}
          onChange={onChange}
        >
          <FormControlLabel
            value={layouts.auto.name}
            control={<Radio />}
            label={t('meetingRoom.layers.auto')}
          />
          <FormControlLabel
            value={layouts.one.name}
            control={<Radio />}
            label={t('meetingRoom.layouts.single')}
          />
          <FormControlLabel
            value={layouts.two.name}
            control={<Radio />}
            label={t('meetingRoom.layouts.two')}
          />
          <FormControlLabel
            value={layouts.four.name}
            control={<Radio />}
            label={t('meetingRoom.layouts.four')}
          />
          <FormControlLabel
            value={layouts.six.name}
            control={<Radio />}
            label={t('meetingRoom.layouts.six')}
          />
          <FormControlLabel
            value={layouts.nine.name}
            control={<Radio />}
            label={t('meetingRoom.layouts.nine')}
          />
          <FormControlLabel
            value={layouts['present-two-upper-6'].name}
            control={<Radio />}
            label={t('meetingRoom.layouts.presentTwoUpper6')}
          />
          <FormControlLabel
            value={layouts['present-upper-6'].name}
            control={<Radio />}
            label={t('meetingRoom.layouts.presentUpper6')}
          />
          <FormControlLabel
            value={layouts['present-lower-3'].name}
            control={<Radio />}
            label={t('meetingRoom.layouts.presentLower3')}
          />
          <FormControlLabel
            value={layouts['present-upper-right-9'].name}
            control={<Radio />}
            label={t('meetingRoom.layouts.presentUpperRight9')}
          />
        </RadioGroup>
      </FormControl>
      <FormControlLabel
        control={
          <Switch onChange={onChangeShowNames} checked={layout.show_names} color="primary" />
        }
        label={t('meetingRoom.layouts.showNames')}
        labelPlacement="start"
      />
    </Modal>
  );
};

export default Layouts;
