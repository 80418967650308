import { createTheme } from '@mui/material/styles';
import { enUS } from '@mui/material/locale';

import globalStyles from './globalStyles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1e94ff',
    },
    secondary: {
      main: '#ff8b1e',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: globalStyles,
    },
  },
  enUS,
});

export default theme;
