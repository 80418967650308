//function to material ui select
const replaceInputs = (arrInputs) => {
  return arrInputs.map((el) => {
    return {
      name: el.label,
      value: el.deviceId,
    };
  });
};

export default replaceInputs;
