import { useMemo } from 'react';

import { menu } from '../menu';
import { pages } from '../pages';
import { useAuth } from '../../context/auth';

export const useAppRouter = () => {
  const { user } = useAuth();

  const authMenu = useMemo(() => {
    return menu.filter((item) => item.isForAuth);
  }, []);

  const navigationMenu = useMemo(() => {
    return menu.filter((item) => !item.isForAuth);
  }, []);

  const targetMenu = useMemo(() => {
    return navigationMenu.filter((item) => (user ? item : !item.private));
  }, [user]);

  return {
    authMenu: authMenu,
    menu: targetMenu,
    pages: pages,
  };
};
