//Common
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import Page404 from '../../pages/Page404';
import SignUp from '../../pages/SignUp';

//Meetings
import MeetingRoom from '../../pages/MeetingRoom';
import RoomManagement from '../../pages/RoomManagement';

export const pages = [
  {
    name: 'home',
    title: 'Welcome',
    component: Home,
    path: '/',
    private: false,
    index: false,
    onlyNonAuth: false,
  },
  {
    name: 'signIn',
    title: 'Sign in',
    component: Login,
    path: '/sign-in',
    private: false,
    index: false,
    onlyNonAuth: true,
  },
  {
    name: 'signUp',
    title: 'Sign up',
    component: SignUp,
    path: '/sign-up',
    private: false,
    index: false,
    onlyNonAuth: true,
  },
  {
    name: 'meetingRoom',
    title: 'Meeting room',
    component: MeetingRoom,
    path: '/rooms/:id',
    private: false,
    index: false,
    onlyNonAuth: false,
  },
  {
    name: 'roomManagement',
    title: 'Room management',
    component: RoomManagement,
    path: '/room-management',
    private: true,
    index: false,
    onlyNonAuth: false,
  },
  {
    name: '404',
    title: 'Page not found',
    component: Page404,
    path: '*',
    private: false,
    index: false,
    onlyNonAuth: false,
  },
];
